.steps-carousel {
    width: 100%;
}

.steps-carousel .carousel__dot {
    height: 14px;
    width: 14px;
    background: #F9F2E6;
    border-radius: 999px;
    transition: background 0.2s;
}

.steps-carousel .carousel__dot--selected {
    background: #D9B166;
}
