.courses-carousel {
    width: 100%;
}

.courses-carousel .carousel__dot {
    background: #DCE2E1;
    border-radius: 999px;
    transition: background 0.2s;
}

.courses-carousel .carousel__dot--selected {
    background: #07332C;
}
